<template>
  <div id="app">
    <TimerView></TimerView>
  </div>
</template>

<script>
import TimerView from './views/TimerView.vue'

export default {
  name: 'App',
  components: {
    TimerView
  },
  data() {
    return {
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth
    }
  },
  watch: {
    windowHeight() {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    },
    windowWidth() {
      let vw = window.innerWidth * 0.01;
      document.documentElement.style.setProperty('--vw', `${vw}px`)
    }
  },
  mounted() {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
      let vw = window.innerWidth * 0.01;
      document.documentElement.style.setProperty('--vw', `${vw}px`)
      window.addEventListener('resize', this.onResize);
  },
  beforeDestroy() { 
    window.removeEventListener('resize', this.onResize); 
  },
  methods: {  
    onResize() {
      this.windowHeight = window.innerHeight
      this.windowWidth = window.innerWidth
    }
  }
}
</script>

<style>
@font-face {
  font-family: "Montserrat";
  src: local("Montserrat"),
  url(./fonts/Montserrat/Montserrat-Regular.ttf) format("truetype");
}
*{
  padding:0;
  margin:0;
  font-family: Montserrat, sans-serif;
}
#app {
  font-family: Montserrat, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  width: calc(var(--vw, 1vw) * 100);
  color: white;
}
</style>
