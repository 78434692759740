<template>
  <div id="container">
    <div id="timer-container">
        <div v-if="timerEnded" class="done">
            <video playsinline id="video" src="../videos/done_anim.mp4" autoplay loop ></video>
        </div>
        <audio id="audio" src="../sounds/finalCountdown.mp3"></audio>
        <div v-show="!timerEnded" id="timer" @click="showSetTimer">
            <input 
                v-show="!setMode && !finalCountdown" 
                :value="getFormattedTime(this.minutes)"
                id="minutesDisplay" 
                disabled
            >
            <p v-show="setMode" class="label label-min">Minutes</p>
            <input type="number" 
                :oninput="validateInput('minutes')"
                v-if="setMode"
                maxlength=""
                v-model="minutesString"
                id="minutes" 
                name="minutes" 
            >
            <p v-if="!finalCountdown || setMode" class="divider">:</p>
            <input 
                v-show="!setMode" 
                :value="getFormattedTime(this.seconds)"
                id="secondsDisplay" 
                disabled
            >
            <p v-show="setMode" class="label label-sec">Seconds</p>
            <input v-show="setMode" type="number" 
                :oninput="validateInput('seconds')"
                maxlength="2"
                v-model="secondsString"
                id="seconds" 
                name="seconds" 
            >
        </div>
        <div v-if="!setMode && !playMode" class="timer-btn" @click="showSetTimer" :style="buttonHover">
            set timer
        </div>
    </div>
    <div class="footer">
        <div class="footer-left">
            <div class="button-container">
                <div id="kickbox-logo">
                    <img src="../assets/kickbox.svg">
                </div>
                <a href="https://www.getkickbox.com/#/login" id="login-btn" :style="buttonHover">
                    Login
                </a>
            </div>
        </div>
        <div class="footer-right">
            <div v-show="!timerEnded" class="onoffswitch">
                <input type="checkbox" name="onoffswitch" v-model="redmode" class="onoffswitch-checkbox" id="myonoffswitch" tabindex="0">
                <label class="onoffswitch-label" for="myonoffswitch">
                    <span class="onoffswitch-inner"></span>
                    <span class="onoffswitch-switch"></span>
                </label>
            </div>
            <div v-if="!playMode && hasTime && !timerEnded" class="control-btn" @click="startTimer" :style="buttonHover">
                <img src="../assets/play.svg">
                <span class="control-label">Play</span>
            </div>
            <div v-if="playMode && !timerEnded" class="control-btn" @click="stopTimer" :style="buttonHover">
                <img src="../assets/pause.svg">
                <span class="control-label">Pause</span>
            </div>
            <div v-if="hasTime && !timerEnded" class="control-btn" @click="resetTimer" :style="buttonHover">
                <img id="reset" src="../assets/reset.svg">
                <span class="control-label">Reset</span>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TimerView',
  data() {
    return {
      minutes: 0,
      seconds: 0,
      minutesString: '00',
      secondsString: '00',
      setMode: false,
      playMode: false,
      timerEnded: false,
      finalCountdown: false,
      hasTime: false,
      redmode: false,
    }
  },
  created() {
    let root = document.documentElement;
    root.style.setProperty('--background', "#131313");
  },
  computed: {
    buttonHover: function() {
        return this.redmode ? { '--button-hover': '#FF4A4A' } : { '--button-hover':  '#232323'} 
    }
  },
  watch: {
    redmode: function () {
        this.handleColorSwitch();
    },
    setMode: function () {
        this.handleColorSwitch();
    }
  },
  methods: {
    async playSound(seconds) {
        var audio = document.getElementById("audio");
        audio.play();
        setTimeout(function(){ 
            audio.pause();
            audio.currentTime = 0;
        }, (seconds * 1000) - 500);
    },
    handleColorSwitch() {
        let root = document.documentElement;
        if(this.redmode && this.setMode) {
            root.style.setProperty('--background', "#F53B3B");
            document.getElementById("container").style.backgroundColor = "#F53B3B";
            document.getElementById("timer").style.backgroundColor = "#FF4A4A";
        } else if(this.redmode && !this.setMode) {
            root.style.setProperty('--background', "#F53B3B");
            document.getElementById("container").style.backgroundColor = "#F53B3B";
            document.getElementById("timer").style.backgroundColor = "#F53B3B";
        } else if(!this.redmode && this.setMode) {
            root.style.setProperty('--background', "#131313");
            document.getElementById("container").style.backgroundColor = "#131313";
            document.getElementById("timer").style.backgroundColor = "#232323";
        } else {
            root.style.setProperty('--background', "#131313");
            document.getElementById("container").style.backgroundColor = "#131313";
            document.getElementById("timer").style.backgroundColor = "#131313";
        }
    },
    validateInput(type) {
        if(type == "seconds") {
            if(parseInt(this.secondsString) > 59 || this.secondsString.length > 2) {
                this.secondsString = this.secondsString.slice(0, -1);
            }
        } else {
            if(parseInt(this.minutesString) > 120 || this.minutesString.length > 3) {
                this.minutesString = this.minutesString.slice(0, -1);
            }
        }
        if (parseInt(this.minutesString) > 0 ||  parseInt(this.secondsString) > 0) {
            this.hasTime = true;
        } else {
            this.hasTime = false;
        }
    },
    getFormattedTime(time) {
        if (time < 10) return `0${time}`;
        return time;
    },
    showSetTimer() {
        if(!this.setMode) {
            this.timerEnded = false;
            this.finalCountdown = false;
            document.getElementById("secondsDisplay").classList.remove("finalCountdown");
            this.minutesString = this.getFormattedTime(this.minutes);
            this.secondsString = this.getFormattedTime(this.seconds);
            this.setMode = true;
            setTimeout(function() { document.getElementById("seconds").focus() }, 100);
        }
    },
    formatActiveTime() {
        if (this.seconds <= 11 && this.minutes == 0) {
            document.getElementById("secondsDisplay").classList.add("finalCountdown");
            this.playSound(this.seconds);
            this.finalCountdown = true;
        }
        if (this.seconds === 0) {
            this.minutes -= 1;
            this.seconds = 59;
        } else {
            this.seconds -= 1;
        }
        if (this.seconds === 0 && this.minutes === 0 && !this.isPaused) {
            document.getElementById("secondsDisplay").classList.remove("finalCountdown");
            this.finalCountdown = false;
            this.playMode = false;
            this.timerEnded = true;
            this.hasTime = false;
            clearInterval(this.interval);
            setTimeout(function() { 
                var vid = document.getElementById("video");
                vid.muted = true;
            }, 4000);
        }
    },
    startTimer() {
        this.minutes = parseInt(this.minutesString);
        this.seconds = parseInt(this.secondsString);
        if (isNaN(this.minutes)) this.minutes = 0;
        if (isNaN(this.seconds)) this.seconds = 0;
        this.setMode = false;
        this.playMode = true;
        this.interval = setInterval(this.formatActiveTime, 1000);
    },
    stopTimer() {
        var audio = document.getElementById("audio");
        audio.pause();
        audio.currentTime = 0;
        this.finalCountdown = false;
        document.getElementById("secondsDisplay").classList.remove("finalCountdown");
        this.minutesString = this.getFormattedTime(this.minutes);
        this.secondsString = this.getFormattedTime(this.seconds);
        this.playMode = false;
        clearInterval(this.interval);
    },
    resetTimer() {
        clearInterval(this.interval);
        document.getElementById("secondsDisplay").classList.remove("finalCountdown");
        var audio = document.getElementById("audio");
        audio.pause();
        audio.currentTime = 0;
        this.minutesString = '00';
        this.secondsString = '00';
        this.minutes = 0;
        this.seconds = 0;
        this.finalCountdown = false;
        this.playMode = false;
        this.setMode = false;
        this.hasTime = false;
    },
  }
}
</script>

<style>
html {
    background-color: var(--background);
}
h3 {
    margin: 40px 0 0;
}
ul {
    list-style-type: none;
    padding: 0;
}
li {
    display: inline-block;
    margin: 0 10px;
}
a {
    color: #42b983;
}
#container {
    display: flex;
    justify-content: center;
    height: 100%;
    width: 100%;
    background-color: #131313;
}
#timer-container {
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    width: fit-content;
    height: fit-content;
    align-self: center;
    align-items: center;
}
#timer-container strong {
    font-size: 200px;
}
#timer-container input {
    background: none;
    border-radius: 20px;
    color: white;
    border: 0;
    font-size: 200px;
    max-width: 400px;
    padding: 0px;
    font-weight: bold;
    letter-spacing: 2px;
}
#timer-container input:focus {
    outline-width: 0;
}
#timer-container input:disabled {
    opacity: 1;
    color: white;
}
.timer-btn {
    padding-top: 10px;
    height: 33px;
    width: 115px;
    border-radius: 6px;
    position: absolute;
    top: 63%;
    z-index: 10;
}
.label {
    position: absolute;
}
.label-min {
    left: 325px;
    top: 15px;
}
.label-sec {
    top: 15px;
    right: 140px;
}
.timer-btn:hover {
    background-color: var(--button-hover);
    cursor: pointer;
}
#timer {
    position: relative;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
}
.done {
    position: absolute;
    height: 400px;
    max-height: 50vh;
    margin: 0;
    z-index: 3;
    bottom: 40%;
}
.done video {
    height: 100%;
    max-width: 80vw;
}
.divider {
    font-weight: bold;
    font-size: 200px;
    margin-top: 0;
    margin-bottom: 20px;
}
#minutesDisplay {
    text-align: end;
}
#minutes {
    text-align: end;
}
#seconds {
    margin-left: 5px;
}
#secondsDisplay {
    margin-left: 5px;
}
.footer {
    width: 100%;
    display: flex;
    flex-direction: row;
    position: absolute;
    bottom: 0;
    height: 70px;
    align-items: center;
}
.footer-left {
    width: 50%;
    display: flex;
    justify-content: flex-start;
    padding-left: 50px;
}
.button-container {
    height: 20px;
    width: 110px;
}
.button-container:hover #kickbox-logo {
    transform: translateY(-200%);
}
.button-container:hover #login-btn {
    display: inline;
}
#kickbox-logo {
    transition: all .5s;
    position: absolute;
}
#login-btn {
    position: absolute;
    height: 20px;
    width: 110px;
    display: none;
    left: 47px;
    bottom: 20px;
    border-radius: 6px; 
    z-index: 10;
    text-decoration: none;
    color: white;
    transition: all 1s;
    padding: 10px 0px;
}
#login-btn:hover {
    background-color: var(--button-hover);
}
.footer-right {
    width: 50%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 40px;
}
#reset {
    height: 20px;
    width: 20px;
}
.control-btn {
    padding: 0px 10px;
    margin: 0px 20px;
    height: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
}
.control-btn:hover {
    background-color: var(--button-hover);
    cursor: pointer;
}
.control-btn img {
    height: 15px;
    width: 15px;
    margin-right: 10px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type=number] {
    -moz-appearance:textfield;
}
.finalCountdown {
    text-align: center;
    font-size: 300px !important;
    height: fit-content !important;
}

.onoffswitch {
    position: relative; width: 75px; right: 20px;
    -webkit-user-select:none; -moz-user-select:none; -ms-user-select: none;
}
.onoffswitch-checkbox {
    position: absolute;
    opacity: 0;
    pointer-events: none;
}
.onoffswitch-label {
    display: block; overflow: hidden; cursor: pointer;
    border: none; border-radius: 20px;
}
.onoffswitch-inner {
    display: block; width: 200%; margin-left: -100%;
    transition: margin 0.3s ease-in 0s;
}
.onoffswitch-inner:before, .onoffswitch-inner:after {
    display: block; float: left; width: 50%; height: 36px; padding: 0; line-height: 35px;
    font-size: 14px; color: white; font-family: Trebuchet, Arial, sans-serif; font-weight: light;
    box-sizing: border-box;
}
.onoffswitch-inner:before {
    content: "dark";
    padding-left: 10px;
    background-color: #FFFFFF; color: #979797;
    text-align: left;
}
.onoffswitch-inner:after {
    content: "red";
    padding-right: 10px;
    background-color: #232323; color: #FFFFFF;
    text-align: right;
}
.onoffswitch-switch {
    display: block; width: 21px; height: 21px; margin: 7.5px;
    background-image: url('../assets/boxly.svg');
    position: absolute; top: 0; bottom: 0;
    right: 38px;
    transition: all 0.3s ease-in 0s; 
}
.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-inner {
    margin-left: 0;
}
.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch {
    right: 0px; 
}

@media only screen and (max-width: 950px) {
    #timer-container input {
        font-size: 150px;
        max-width: 300px;
    }
    .label {
        font-size: 15px;
    }
    .label-min {
        left: 230px;
        top: 10px;
    }
    .label-sec {
        top: 10px;
        right: 110px;
    }
    .finalCountdown {
        font-size: 200px !important;
    }
    .done {
        font-size: 200px;
        padding-bottom: 20px;
    }
    .divider {
        font-size: 150px;
    }
}

@media only screen and (max-width: 650px) {
    .control-label {
        display: none;
    }
    .control-btn img {
        margin-right: 0px;
    }
    .control-btn {
        width: 15px;
        margin: 5px;
    }
    .footer-right {
        padding-right: 10px;
    }
    .footer-left {
        padding-left: 22px;
    }
    #timer-container input {
        font-size: 100px;
        max-width: 200px;
    }
    .label {
        font-size: 12px;
    }
    .label-min {
        margin-top: 0px;
        left: 142px;
    }
    .label-sec {
        margin-top: 0px;
        right: 70px;
    }
    .finalCountdown {
        font-size: 150px !important;
    }
    .done {
        font-size: 140px;
        padding-bottom: 50px;
    }
    .divider {
        font-size: 100px;
    }
}

@media only screen and (max-width: 450px) {
    #timer-container input {
        margin-top: 10px;
        font-size: 70px;
        max-width: 130px;
    }
    .label {
        font-size: 10px;
        top: 9px;
    }
    .label-min {
        margin-top: 0px;
        left: 85px;
    }
    .label-sec {
        margin-top: 0px;
        right: 37px;
    }
    #timer {
        height: 120px;
    }
    .finalCountdown {
        font-size: 100px !important;
    }
    .done {
        font-size: 100px;
        padding-bottom: 50px;
    }
    .divider {
        font-size: 70px;
        margin-top: 5px;
        margin-bottom: 0px;
    }
}

</style>
